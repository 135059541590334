<template>
  <overlay
    :show="loading"
  >
    <div class="content-wrapper">
      <PageHeader
        :screen-name="pageTitle"
        :link-items="linkItems"
      />

      <TimedAlert
        variant="danger"
        :message="erroMessage"
        :show="errorAlert"
      />

      <div class="card p-2">
        <b-row>
          <b-col
            sm="6"
            lg="4"
          >
            <div class="mb-4">
              <h5>Nome completo*</h5>
              <p>{{ usuario.nome }}</p>
            </div>

            <div class="mb-4">
              <h5>CPF*</h5>

              <p>{{ usuario.cpf | VMask('###.###.###-##') }}</p>
            </div>

            <div class="mb-4">
              <h5>Perfil*</h5>
              <p>{{ usuario.perfil.descricao }}</p>
            </div>
          </b-col>

          <b-col
            sm="6"
            lg="4"
          >
            <div class="mb-4">
              <h5>E-mail*</h5>
              <p>{{ usuario.email }}</p>
            </div>

            <div class="mb-4">
              <h5>Sexo*</h5>
              <p>{{ usuario.sexo }}</p>
            </div>

            <div class="mb-4">
              <h5>Data de nascimento*</h5>
              <p>{{ usuario.nascimento }}</p>
            </div>
          </b-col>

          <b-col
            v-if="getUoProfileMode"
            sm="6"
            lg="4"
          >
            <div class="mb-4">
              <h5>Unidades*</h5>
              <div class="d-flex flex-wrap">
                <div
                  v-for="(unidade, index) in usuario.unidades"
                  :key="index"
                  class="rounded regioes"
                >
                  {{ unidade.descricao }}
                </div>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            sm="6"
            lg="4"
          >
            <div class="mb-4">
              <h5>Atua com empresas de base nacional ?</h5>
              <p>{{ usuario.tipo_atuacao.length > 0 ? 'Sim' : 'Não' }}</p>
            </div>
          </b-col>

          <b-col
            v-if="usuario.tipo_atuacao.length > 0"
            sm="6"
            lg="4"
          >
            <div class="mb-4">
              <h5>Tipo de atuação</h5>
              <p>{{ usuario.tipo_atuacao[0].descricao }}</p>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            sm="6"
            lg="4"
          >
            <div class="mb-4">
              <h5>Vacinador ?</h5>
              <p>{{ usuario.idDocumento != null ? 'Sim' : 'Não' }}</p>
            </div>
          </b-col>
        </b-row>

        <b-row v-if="usuario.idDocumento != null">
          <b-col sm="6" lg="4">
            <div class="mb-4">
              <h5>Sigla do Conselho de Classe</h5>
              <p>{{ usuario.tipoDocumento }}</p>
            </div>
          </b-col>
          <b-col sm="6" lg="4">
            <div class="mb-4">
              <h5>Número do Conselho de Classe</h5>
              <p>{{ usuario.numeroDocumento }}</p>
            </div>
          </b-col>
          <b-col sm="6" lg="4">
            <div class="mb-4">
              <h5>Estado</h5>
              <p>{{ usuario.estadoDocumento }}</p>
            </div>
          </b-col>
        </b-row>

        <b-row v-if="canShowCancelVacinados">
          <b-col
            sm="6"
            lg="4"
          >
            <div class="mb-4">
              <h5>Cancelar Vacinados ?</h5>
              <p>{{ usuario.permite_estornar_vacinacao ? 'Sim' : 'Não' }}</p>
            </div>
          </b-col>
        </b-row>

        <b-row
          v-if="canManage"
          class="buttons"
        >
          <b-col cols="12">
            <b-button
              id="redirect-edit"
              class="mr-2"
              variant="custom-blue"
              :disabled="loadingData || disabledEdit"
              @click.prevent="redirectToEdit"
            >
              <feather-icon
                icon="EditIcon"
              />
              Editar
            </b-button>
          </b-col>
        </b-row>
      </div>
    </div>
  </overlay>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'
import moment from 'moment'
import { setIdUsuario } from '@core/utils/store/setStore'
import { actions, subjects } from '@/libs/acl/rules'

import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import TimedAlert from '@/views/components/custom/timed-alert/TimedAlert.vue'
import { getIdUsuario } from '@/@core/utils/store/getStore'
import Overlay from '@/views/components/custom/overlay/Overlay.vue'
import { getAdminSesiId } from '@/views/custom-pages/gerenciarUsuarios/requests'
import chavePerfil from '@/enum/chavePerfil'

export default {

  title: 'Ver usuário',

  components: {
    Overlay,
    PageHeader,
    TimedAlert,
    BRow,
    BCol,
    BButton,
  },

  data() {
    return {
      linkItems: [
        {
          name: 'Gerenciar usuários',
          routeName: 'usuario-list',
        },
        {
          name: '...',
          active: true,
        },
      ],

      loading: true,

      erroMessage: '',
      errorAlert: false,
      pageTitle: '...',
      loadingData: true,
      usuario: {
        id_usuario: '',
        nome: '-',
        cpf: '-',
        email: '-',
        nascimento: '-',
        sexo: '',
        perfil: {
          id_perfil: '',
          chave: '',
          descricao: '',
        },
        departamento: '-',
        unidades: [],
        idPerfil: null,
        tipo_atuacao: [],
        permite_estornar_vacinacao: false,
        idDocumento: null,
        tipoDocumento: null,
        numeroDocumento: null,
        estadoDocumento: null,
      },
      canManage: false,
      canShowCancelVacinados: false,
      disabledEdit: false,
    }
  },

  computed: {
    getProfileKey() {
      return this.usuario.perfil ? this.usuario.perfil.chave : null
    },

    getUoProfileMode() {
      return [
        chavePerfil.SAUDE_REGIAO,
        chavePerfil.ADMIN_UNIDADE,
        chavePerfil.VACINADOR_PADRAO,
        chavePerfil.VACINADOR_ADMIN,
        chavePerfil.SAUDE_UO,
      ].includes(this.getProfileKey)
    },
    
  },

  mounted() {
    if ((getIdUsuario() === null || typeof getIdUsuario() === 'undefined') && typeof this.$route.params.idUsuario === 'undefined') {
      return this.openModalError()
    }

    if (typeof this.$route.params.idUsuario !== 'undefined') {
      if (this.$route.params.idUsuario !== getIdUsuario()) {
        setIdUsuario(this.$route.params.idUsuario)
      }
    }

    this.canManage = this.$can(actions.GERENCIAR, subjects.USUARIO_SESI_MENUS_ROTAS)

    this.canShowCancelVacinados = this.$can(actions.VISUALIZAR, subjects.CANCELAR_VACINADOS)
  
    this.loadDataUsuario()
  },

  methods: {
    async loadDataUsuario() {
      this.loading = true

      await getAdminSesiId(getIdUsuario()).then(({ data }) => {
        this.loadingData = false

        this.pageTitle = this.linkItems[1].name = data.nome

        this.usuario.id_usuario = data.id_usuario
        this.usuario.nome = data.nome
        this.usuario.cpf = data.cpf
        this.usuario.nascimento = moment(data.data_nascimento, 'YYYY-MM-DD').format('DD/MM/YYYY')
        this.usuario.email = data.email
        this.usuario.tipo_atuacao = data.tipo_atuacao
        this.usuario.permite_estornar_vacinacao = data.permite_estornar_vacinacao

        if(data.id_tipo_documento != null) {
          this.usuario.idDocumento = data.id_tipo_documento;
          this.usuario.tipoDocumento = data.documento_sigla;
          this.usuario.numeroDocumento = data.documento;
          this.usuario.estadoDocumento = data.documento_uf;
        }

        this.usuario.genero = 'Não informado'
        if (data.sexo) {
          this.usuario.sexo = data.sexo
        }

        this.usuario.perfil = 'Não selecionado'
        if (data.perfil.length > 0) {
          this.usuario.perfil = data.perfil[0]
          this.usuario.idPerfil = data.perfil[0].id_perfil
        }

        this.usuario.departamento = 'Não selecionado'
        if (data.departamentoNome) {
          this.usuario.departamento = data.departamentoNome
        }
        this.usuario.unidades = data.unidade
        this.disabledEdit = !data.pode_editar_usuario
      }).catch(({ response: { data } }) => {
        this.erroMessage = data.errors.idUsuario[0]
        this.errorAlert = !this.errorAlert
      })

      this.loading = false
    },

    redirectToList() {
      this.$router.push({ name: 'usuario-list' })
    },

    redirectToEdit() {
      this.$store.commit('adminUserState/setAdminUserUpdate', this.usuario)

      this.$router.replace({ name: 'usuario-edit' })
    },
  },
}
</script>

<style>
.regioes{
    background-color: #2772C0;
    color: white;
    padding: 5px;
    margin-right: 10px;
    margin-bottom: 5px;
    font-weight: 400;
}
</style>
